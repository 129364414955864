<template>
  <div class="in-workbench">
    <div class="left">
      <el-button type="primary" @click="dialog" style="text-align: left">新建物料信息</el-button>
    </div>
    <div class="blank20"></div>
    <el-row :gutter=20>
      <el-col :span=24>
        <el-table :data="tableData" border>
          <el-table-column prop="name" :min-width=150 label="物料名称"></el-table-column>
          <el-table-column prop="unit" :min-width=120 label="单位"></el-table-column>
          <el-table-column prop="price" :min-width=120 label="价格"></el-table-column>
          <el-table-column prop="type" :min-width=120 label="类型"></el-table-column>
          <el-table-column :resizable="false" align="center" :width=100 label="操作">
            <template slot-scope="scope">
              <el-link @click="updatamanagement(scope.row)">编辑</el-link>&nbsp;
              <el-link @click="deletemanagement(scope.row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="block">
      <span class="demonstration"></span>
      <el-pagination
        @size-change="handleSizeChange"
        :current-page.sync="pages" @current-change="handleCurrentChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="pagetotal">
      </el-pagination>
    </div>
    <!--新增物料信息-->
    <template>
      <el-dialog :title="flag?'修改物料信息':'新增物料信息'" :visible.sync="managementinfo" :append-to-body="true" width="50%"
                 top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
        <el-form>
          <el-row>
            <el-col :xs=24 :sm=12>
              <el-form-item label="物料名称" label-width="80px">
                <el-input v-model="addmanagementinfo.name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs=24 :sm=12>
              <el-form-item label="物料单位" label-width="80px">
                <template>
                  <el-select v-model="addmanagementinfo.unit" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :xs=24 :sm=12>
              <el-form-item label="物料价格" label-width="80px">
                <el-input v-model="addmanagementinfo.price"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs=24 :sm=12>
              <el-form-item label="物料类型" label-width="80px">
                <template>
                  <el-select v-model="addmanagementinfo.type" placeholder="请选择">
                    <el-option
                      v-for="item in option"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureadd">确定新增</el-button>
        <el-button type="danger" @click="back">取消新增</el-button>
      </span>
      </el-dialog>
    </template>

  </div>
</template>


<script>
import clientUtils from "../../js/clientUtils";

export default {
  name: "Material management",
  data() {
    return {
      tableData: [],
      managementinfo: false,
      addmanagementinfo: {},
      flag: false,
      pages: 0,
      fillpage: 1,
      pagetotal: null,
      options:[],
      option:[{id:1,name:"易损品"},{id:2,name:"消耗品"}]
    }
  },
  methods: {
    loadAllTabledData: function () {
      const that = this;
      clientUtils.get('/system/goods/findByPage?current=1&size=10', function (res) {
        if (res.data) {
          console.log(res.data.data);
          that.tableData = res.data.data.records;
          that.pagetotal = parseInt(res.data.data.total);
        }
      });
    },
    dialog: function () {
      this.managementinfo = true
      const that = this
      clientUtils.get('/system/dict/findAll?key=物料',function (res) {
        if (res.data!=null){
          that.options=res.data.data
          // console.log(JSON.stringify(res.data.data))
        }
      })
    },
    back: function () {
      this.managementinfo = false;
      this.addmanagementinfo = {};
      this.flag = false
    },
    sureadd: function () {
      console.log(JSON.stringify(this.addmanagementinfo));
      let obj = JSON.stringify(this.addmanagementinfo);
      const that = this;
      let url;
      if (this.flag) {
        url = 'system/goods/updateById'
      } else {
        url = 'system/goods/save'
      }
        clientUtils.post(url,obj,function (res) {
          if (res.data!=null){
            clientUtils.popSuccessMessage("新增成功")
            that.back()
            that.Variety()
          }
        })
    },
    updatamanagement: function (info) {
      this.managementinfo = true;
      this.flag = true;
      this.addmanagementinfo=JSON.parse(JSON.stringify(info))
      const that = this
      clientUtils.get('/system/dict/findAll?key=物料',function (res) {
        if (res.data!=null){
          that.options=res.data.data
          // console.log(JSON.stringify(res.data.data))
        }
      })
    },
    //删除物理信息
    deletemanagement: function (info) {
      console.log(JSON.stringify(info));
      const that = this;
      this.$confirm("确定要删除"+info.name+"这条物料数据吗?" ,clientUtils.Tip,{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(()=>{
        info.deleteFlag=true;
        console.log(JSON.stringify(info));
        console.log(that.tableData.length);
        clientUtils.post('/system/goods/removeByIds?ids=' + info.id, null, function (res) {
          if (res.data != null) {
            clientUtils.popSuccessMessage("删除成功");
            if (that.tableData.length==1){
              that.fillpage = that.fillpage-1;
              console.log(that.fillpage);
              that.Variety()
            }else {
              that.Variety()
            }
          }
        })
      })

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      console.log(val);
      this.fillpage = `${val}`;
      console.log('页码' + this.fillpage);
      const that = this;
      clientUtils.get('/system/goods/findByPage?current=' + this.fillpage + '&size=10', function (res) {
        if (res.data) {
          console.log(res.data.data);
          that.tableData = res.data.data.records;
          that.pagetotal = parseInt(res.data.data.total);
        }
      });

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    Variety: function () {
      const that = this;
      clientUtils.get('/system/goods/findByPage?current=' + this.fillpage + '&size=10', function (res) {
        if (res.data) {
          console.log(res.data.data);
          that.tableData = res.data.data.records;
          that.pagetotal = parseInt(res.data.data.total);
          console.log("bianhua" + that.pagetotal)
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('selectMenu', this.$route.path);
      this.loadAllTabledData();
    })
  }
}
</script>

<style scoped>
.left {
  text-align: left;
}
</style>
